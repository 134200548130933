<script setup>

const topButton = ref()

const scrollToTop = () => {
  if (typeof window !== 'undefined')
  window.scrollTo(0,0);
}

function scrollFunction() {
  if (typeof topButton.value !== 'undefined' && topButton.value) {
    if (document.body.scrollTop > 160 || document.documentElement.scrollTop > 160) {
      topButton.value.style.display = "block";
    } else {
      topButton.value.style.display = "none";
    }
  }
}

onMounted(() => {
  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function() {scrollFunction()};
})
</script>

<template>
  <button class="scroll-to-top-btn" style="display: none" @click="scrollToTop" ref="topButton">{{ $t('app.scroll-to-top') }}</button>
</template>

<style>

</style>

